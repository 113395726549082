import React from 'react';

interface CanvasProps{}

const Canvas: React.FC<CanvasProps> = (props) => {
    return (
      <>  
          <canvas {...props} width="100vw" height="100vh" />
      </>
    )
};

export default Canvas