import { Box, Input } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import {
  OpaliteTextTitle,
  OpaliteTextStandard,
} from "../shared/opalite_text";
import { OpaliteButtonStandard } from "../shared/opalite_buttons";
import Canvas from "../waves/canvas";

interface StayInformedProps {}

const StayInformed: React.FC<StayInformedProps> = (props) => {
    useEffect(() => {
      var script = document.createElement("script");
      script.src = "../../../noise.js";
      script.async = false;
      document.body.appendChild(script);

      var script2 = document.createElement("script");
      script2.src = "../../../animate_waves.js";
      script2.async = false;
      document.body.appendChild(script2);
    }, [])


    return (
    <Box
      height="100vh"
      backgroundColor={COLORS_PRIMARY.BACKGROUND}
    >
      <Canvas/>
      <Box
        display="flex"
        flexDirection="column"
        position="fixed"
        bottom="2rem"
        width={{ base:"80%", md:"530px", lg:"530px" }}
        marginInline="10%"
      >
      <Box
        width={{ base:"100%", md:"630px" }}
      >
        <OpaliteTextTitle width="60%" marginBottom="1rem" fontWeight="bold">
          Stay informed
        </OpaliteTextTitle>
        <OpaliteTextStandard marginBottom="1rem">
          Get the latest scoop on the Opalite influencer NFT platform
        </OpaliteTextStandard>
        <Box
          display="flex"
          flexDirection="row"
        >
          <Input
            color={COLORS_PRIMARY.FONT}
            marginBottom="1rem"
            placeholder="you@email.com"
            // onChange={(e) => setCollectionName(e.target.value)}
            width="40%"
            paddingRight=".5rem"
          />
          <OpaliteButtonStandard
            // onClick={() => ...}
            width={{ base:"33%", md:"23.5%" }}
            marginBottom="1rem"
            marginLeft=".5rem"
          >
            Subscribe
          </OpaliteButtonStandard>
        </Box>
        <OpaliteButtonStandard
          // onClick={() => ...}
          width={{ base:"75%", md:"65%" }}
          marginBottom="1rem"
        >
          Join our Discord
        </OpaliteButtonStandard>
      </Box>
    </Box>
  </Box>
  );

};

export default StayInformed
