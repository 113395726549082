import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import Header from "../components/header/header";
import StayInformed from "../components/stay_informed/stay_informed";

function StayInformedPage() {
  return (
    <ChakraProvider>
      <Header />
      <StayInformed/>
    </ChakraProvider>
  );
}

export default StayInformedPage;

